
import { computed, defineComponent, onMounted, reactive, ref, watchEffect } from "vue";
import authenticationService from "@/services/authentication.service";
import { Form, Field, validate } from "vee-validate";
import { FieldError, IconEyeClosed, IconEyeOpen,OtpScreen,OtpExpirationHelpText } from "@/components";
import * as yup from "yup";
import { useRoute, useRouter } from "vue-router";
import { decode, encodeWithParam } from "@/util/DataEncodeDecode";
import { mutation } from "@/harlem/mutation";
import { state } from "@/harlem/stores";
import { setStatus } from "@/services/stage.service";
import { signup } from "@/http/http.config";
import { proxyApi } from "@/http/http.config";
import { gTagEvent } from "@/util/GoogleAnalytics";
// import { getDataForAppState } from "./GetDataForAppState";
import { FunnelStatus } from "@/util/FunnelStatus";
import { setPaymentAgreementComplete } from "@/harlem/mutation/payment.mutation";
// import { SUCCESS_CODES } from "./constants";
import micromodal from "micromodal";

export default defineComponent({
  name: "Login",
  components: { Form, Field, FieldError, IconEyeClosed, IconEyeOpen,OtpScreen ,OtpExpirationHelpText},
  setup() {
    var countLeft = ref<boolean | string>(false);
    var errorMessage = ref(false);
    const router = useRouter();
    const route = useRoute();
    const invalid = ref(false);
    const error = ref(false);
    const header = ref(true);
    const expiredPassword = ref(false);
    const passwordHidden = ref(true);
    const username = ref("");
    const password = ref("");
    const isLoginButtonDisabled = ref(true);
    const showsendotp=ref(false);
    const otp=ref("");
    let lastFourPhone=ref("")
    // const isVerifyButtonDisabled = ref(true);
    const isFreezingTime=ref(false);
    let invalidAcc=ref(false);
    const invalidOtp=ref(false)
    const isSignupFlag = ref(false)

    onMounted(() => {
      gTagEvent("Enter Login Page", "Login", true)
      localStorage.clear()
      sessionStorage.clear();
      // validations();

  })
    watchEffect(() => {
      isLoginButtonDisabled.value = (username.value?.length > 0 && password.value?.length > 7) ? false : true;
      // isVerifyButtonDisabled.value= (otpValues.value?.length == 6 ) ? false : true;
      // isVerifyButtonDisabled.value = otpValues?.value?.some(value => value=== '');
    })

    const signIn = async () => {
      sessionStorage.setItem('notified', 'true')
      let obj = {}
      if(isSignupFlag.value){
         obj = {
            username: username.value.trim(),
            password: password.value,
            isSignup: true
          };
      }else{
         obj = {
            username: username.value.trim(),
            password: password.value,
            otp:otpValue.value
        };
      }
      

      const beforeDocumentCompleteSteps = [
        "PERSONAL_DETAILS",
        "BUSINESS_DETAILS",
        "CONFIRMATION",
        "SIGNATURE",
        "DOCUMENTS",
        "PAYMENT_AGREEMENT",
      ]

      authenticationService
        .login(encodeWithParam(obj))
        .then(async (data: any) => {
          if (data.data.success) {
            invalid.value = false;
            expiredPassword.value = false;
            localStorage.setItem("username", username.value);
            
            const userObj = decode(data.data.result);
            localStorage.setItem("comm_email",userObj.user.comm_email)
            // console.log(userObj, "RESULT")
            userObj.user.firstName = decodeURIComponent(userObj.user.firstName);
            userObj.user.lastName = decodeURIComponent(userObj.user.lastName);
            gTagEvent("Login Successful", "Login", userObj?.user?.partnerID)
            singletonObject(userObj, obj);

            let today = new Date();
            let formattedDateToday = today.toISOString().split('T')[0];
            let insuranceExpiryDate = userObj.user.insuranceExpiry;
            let migratedFlag = userObj.user.migratedData;
            localStorage.setItem("isMigratedUser",migratedFlag)
            let insruanceExpiryFlag = "";
            if(insuranceExpiryDate < formattedDateToday ){
              insruanceExpiryFlag = "Expired";
            }else{
              insruanceExpiryFlag = "";
            }

            let ocrFlag = userObj.user.insurance_verified;
              

            if(userObj.user.candidateStatus=='Withdrawn' ){
             mutation.payment.setfunnelStatus(false)
            }else {
            mutation.payment.setfunnelStatus(true)
             }

            if (userObj.user.candidateStatus == FunnelStatus.ENROLLMENT_COMPLETE || userObj.user.candidateStatus == FunnelStatus.ENROLLMENT_ON_HOLD){
              let thresholdCheck:any= await signup.post("/thresholdCheck", encodeWithParam({ clientLocationID: userObj.user.clientLocationID }))
                if(thresholdCheck.data.success && !thresholdCheck.data.allowSignup){
                  singletonObject(userObj, obj);
                  return router.push({ name: 'LocationUpdateCa', query: { locationId: state.user.locationId } })
                }
                proxyApi.post('/addApplicationStatus', encodeWithParam({ status: FunnelStatus.PERSONAL_DETAILS_PENDING}));
                userObj.user.candidateStatus = FunnelStatus.PERSONAL_DETAILS_PENDING
                // here we need redirection inside dashboard
                redirectPages(FunnelStatus.PERSONAL_DETAILS_PENDING,insruanceExpiryFlag,ocrFlag,migratedFlag);
            }else{
              redirectPages(userObj.user.candidateStatus,insruanceExpiryFlag,ocrFlag,migratedFlag);
            }

            
            
           
          }else{
            invalidAcc.value=true;
              if(data.data.accountLocked){
                  return router.push({
                    name: "accountLockedCA",
                    });
                }

              if(!data.data.success && !data.data.result){
                errorMessage.value = data.data.message;
                error.value = true;
                invalid.value = false;
                header.value = true;
              }else{
                countLeft.value = data.data.result[0].failed_login_attempts;
                invalid.value = true;
                header.value = false;
                error.value = false;
              }
                
                
            // const { data } = await proxyApi.post("/checkFailedAttemptsCA", encodeWithParam({ username: obj.username }));
            // console.log(data)
            // if(!data.success){
            //   errorMessage.value = data.message;
            //   error.value = true;
            //   invalid.value = false;
            //   header.value = true;

              
            // }
            // else{
                
            //   }
            
            

            if(data.data.forgotPassword){
              return router.push({
                name: "ForgotPasswordCA",
              });
            }
            // else{
            //   error.value = true;
            //   errorMessage.value = data.data.message;
            // }
            
          }
          
        })
        .catch(({ response }) => {
          console.log(response)
          if (response.data.code === "InvalidCredentials") {
            invalid.value = true;
          }
        });

      
    };

    const redirectPages = (status,insruanceExpiryFlag,ocrFlag,migratedFlag) => {

if(status == "Personal Details Pending" || status == "Payment Agreement Pending" || status == "Vehicle Info and Insurance Pending"){ 
  if(status == "Personal Details Pending"){ 
    sessionStorage.setItem("statusWarningMessage", "Personal information incomplete");
    return router.push({
          name: "ca-dashboard",
        });
  }else if(status == "Payment Agreement Pending"){ 
     proxyApi.get('/checkPaymentRaw')
      .then(async ({data}) => {
        if (data.rowCount > 0) { 
          if(data.result[0].response_text == "Created"){
            sessionStorage.setItem("cardType","PERSONAL_DETAILS")
            sessionStorage.setItem("progress","PERSONAL_DETAILS")
            sessionStorage.setItem("OCR_STATUS", "processing")

            return  router.push({ 
            name: 'enrollment-checklist', // need confirmation from Business
          })
          }
        }else{
          sessionStorage.setItem("statusWarningMessage", "Earning account setup incomplete");
          return router.push({
          name: "ca-dashboard",
        });
        }
        
      })
    // 
  }else if(status == "Vehicle Info and Insurance Pending"){
    sessionStorage.setItem("statusWarningMessage", "Insurance upload incomplete");
    return router.push({
          name: "ca-dashboard",
        });
  }
  
}

if(status == 'Payment Agreement Complete'){

  proxyApi.get('/getDriverKYCstatus')
  .then(async ({data}) => {
    if (data.rowCount > 0) { 
      if(data.result.onboard_status == 'payable'){
        sessionStorage.setItem("cardType","INSURANCE_UPLOAD")
        sessionStorage.setItem("progress","INSURANCE_UPLOAD")
        sessionStorage.setItem("OCR_STATUS", "in-process")

      router.push({ 
        name: 'enrollment-checklist', // need confirmation from Business
      })
       
      }else{
        sessionStorage.setItem("cardType","PERSONAL_DETAILS")
        sessionStorage.setItem("progress","PERSONAL_DETAILS")
        sessionStorage.setItem("OCR_STATUS", "processing")

        router.push({ 
        name: 'enrollment-checklist', // need confirmation from Business
      })
      }
    } 
  })
}
if(status == "Enrollment On-Hold"){
  return router.push({
          name: "LocationUpdateCa",
        });
}

if(status == "IDV Pending"){
  // proxyApi.get('/getDriverKYCstatus')
  // .then(async ({data}) => {
  // 	if (data.rowCount > 0) { 
  //     if(data.result.kyc_status == 'pending-manual-verification'){
  //       router.push({ 
  //           name: 'kycPendingManual', 
  //       })
  //     }else{
  // 		router.push({ 
  // 		name: 'kycPending',
  // 	})
  // 	}
  // 	}else{
  // 		router.push({ 
  // 		name: 'kycPending',
  // 	})
  // 	}
  // })

  // new flow for payfare KYC
  return router.push({ 
            name: 'kycProcessing', 
          })
}

if(status == "IDV Fail"){
  proxyApi.get('/getDriverKYCstatus')
  .then(async ({data}) => {
    if (data.rowCount > 0) { 
      let kycStatus = data.result?.kyc_status;
      let failedAttepmts = data.result?.failed_attempts_count;
      let kycThreshold = data.kycthreshold;

      if(kycStatus == 'failed'){
        if(failedAttepmts < kycThreshold){
          return router.push({ 
            name: 'ReviewEdit', 
          })
        }else{
          return router.push({ 
            name: 'kycFail',
          })
        }
      }
    }
  })
  
}

if(status == 'OCR Failed' || status == 'OCR Clear' || status == 'Checkr Package Submitted' || status == 'Checkr Package Consider' || status == 'Checkr Invitation Sent' || status == 'Manual Clear'){

  if(status == 'OCR Failed'){
    sessionStorage.setItem("cardType","INSURANCE_UPLOAD")
    sessionStorage.setItem("progress","INSURANCE_UPLOAD")
    sessionStorage.setItem("OCR_STATUS", "failed")
  }else{
    sessionStorage.setItem("cardType","BACKGROUND_CHECK")
    sessionStorage.setItem("progress","BACKGROUND_CHECK")
    sessionStorage.setItem("OCR_STATUS", "clear")
  }

  proxyApi.get('/getDriverKYCstatus')
  .then(async ({data}) => {
    if (data.rowCount > 0) { 
      if(data.result.onboard_status == 'payable'){
        router.push({ 
            name: 'enrollment-checklist', // need to check if he is ocr failed or pass
        })
      }else{
        sessionStorage.setItem("cardType","PERSONAL_DETAILS")
        sessionStorage.setItem("progress","PERSONAL_DETAILS")
        sessionStorage.setItem("OCR_STATUS", "processing")
      router.push({ 
      name: 'enrollment-checklist', // need confirmation from Business
    })
    }
    }else{
      router.push({ 
      name: 'enrollment-checklist',
    })
    }
  })
  
}

if(status == 'Insurance Image Invalid'){
  sessionStorage.setItem("cardType","INSURANCE_UPLOAD")
    sessionStorage.setItem("progress","INSURANCE_UPLOAD")
    sessionStorage.setItem("OCR_STATUS", "failed")
    router.push({ 
      name: 'enrollment-checklist', // need confirmation from Business
    })
}

if(status == 'Withdrawn' || status == 'Checkr Package Denied' || status == 'Checkr Package Suspended' || status == 'DMS Data Issue'){
  sessionStorage.setItem("cardType","EARNINGS_STATUS")
    sessionStorage.setItem("progress","EARNINGS_STATUS")
    sessionStorage.setItem("OCR_STATUS", "Withdrawn")
    router.push({ 
      name: 'enrollment-checklist', // need confirmation from Business
    })
}

if(status == 'Application Waitlist'){
    sessionStorage.setItem("cardType","EARNINGS_STATUS")
    sessionStorage.setItem("progress","EARNINGS_STATUS")
    sessionStorage.setItem("ocrFlag",ocrFlag);
    

   if(insruanceExpiryFlag == 'Expired'){
    sessionStorage.setItem("OCR_STATUS", "insurance_expired")
    router.push({ 
      name: 'enrollment-checklist',  
    })
   }else{
    sessionStorage.setItem("OCR_STATUS", "Add to waitlist")
    router.push({ 
      name: 'enrollment-checklist',  
    })
   }
    
}

if (status == 'Approved Manual' || status == 'Approved Auto') {
  console.log(migratedFlag,"migratedFlag")
  if(ocrFlag == false && migratedFlag == false){
    sessionStorage.setItem("cardType","EARNINGS_STATUS")
    sessionStorage.setItem("progress","EARNINGS_STATUS")
    sessionStorage.setItem("ocrFlag",ocrFlag);
    sessionStorage.setItem("driverStatus", "approved_expired");
    return router.push({ 
      name: 'enrollment-checklist',  
    })
  }
  


  if(insruanceExpiryFlag == 'Expired' && migratedFlag == false){
    sessionStorage.setItem("cardType","EARNINGS_STATUS")
    sessionStorage.setItem("progress","EARNINGS_STATUS")
    sessionStorage.setItem("OCR_STATUS", "insurance_expired");
    sessionStorage.setItem("driverStatus", "approved_expired");
    return router.push({ 
      name: 'enrollment-checklist',  
    })
  }else{
    sessionStorage.setItem("driverStatus", "approved");
    return router.push({ 
      name: 'ca-earnings-account-home', 
    })
  }
  
}

 






// switch (status) {
//   case 'Personal Details Pending':
//     sessionStorage.setItem("statusWarningMessage", "Personal information incomplete");
//     return router.push({ name: 'ca-dashboard' })
//   case 'Payment Agreement Pending':
//     sessionStorage.setItem("statusWarningMessage", "Earning account setup incomplete");
//     return router.push({ name: 'ca-dashboard' })
//   case 'Vehicle Info and Insurance Pending':
//     sessionStorage.setItem("statusWarningMessage", "Insurance upload incomplete");
//     return router.push({ name: 'ca-dashboard' })
//   case 'Payment Agreement Complete':
//     return router.push({ name: 'enrollment-checklist' })
//   case 'IDV Pending':
//     return router.push({ name: 'kycPendingManual' })
//   case 'IDV Fail':
//     return router.push({ name: 'kycFail' })
//   case 'OCR Failed':
//     sessionStorage.setItem("cardType","INSURANCE_UPLOAD")
//     sessionStorage.setItem("progress","INSURANCE_UPLOAD")
//     sessionStorage.setItem("OCR_STATUS", "failed")
//     return router.push({ name: 'enrollment-checklist' })
//   case 'OCR Clear':
//     return router.push({ name: 'enrollment-checklist' })
//   case 'Checkr Package Submitted':
//     return router.push({ name: 'enrollment-checklist' })
// }



};

    const isPasswordExpired = (date) => {
      var g1 = new Date();
      var g2 = new Date(date);
      if (g1.getTime() < g2.getTime()) return false;
      else if (g1.getTime() > g2.getTime()) return true;
    };

    const redirectToForgotPassword = () => {
      return router.push({ name: "forgotPassword" });
    };

    const viewPassword = computed(() => {
      return passwordHidden.value ? "password" : "text";
    });
    const togglePassword = () => (passwordHidden.value = !passwordHidden.value);
    const initialValues = reactive({
      username: "",
      password: "",
      
    });
    const schema = yup.object().shape({
      username: yup.string().trim().required().label("Username"),
      password: yup.string().required().min(8).label("Password"),
    });



    const singletonObject = (data, obj) => {
      mutation.user.setGarnishment(data.user.garnishment);
      mutation.user.setIsOneIntegrated(data.user.is_one_integration_enabled);
      mutation.user.setOneStatus(data.user.one_status);
      mutation.user.setIsOneActive(data.user.is_active);
      
      mutation.user.setOneStartDate(data.user.one_pay_start_date);

      mutation.user.setPartnerId(data.user.partnerID);
      mutation.user.setUserId(data.user.userID);
      mutation.user.setEmail(data.user.email);
      mutation.user.setCommEmail(data.user.comm_email);
      mutation.user.setFirstName(data.user.firstName);
      mutation.user.setLastName(data.user.lastName);
      mutation.user.setPhone(data.user.phone);
      mutation.user.setLocationId(data.user.clientLocationID);
      mutation.user.setLocationName(data.user.locationName as string);
      mutation.user.setLocationPartnerId(data.user.clientLocationPartnerID);
      mutation.user.setUsername(obj.username);
      if(data.user.electronicSignature &&  data.user.electronicSignature.includes('%'))
      {
      mutation.user.setSignature(decodeURIComponent(data.user.electronicSignature));
      mutation.mergeTags.setPartnerSignature(decodeURIComponent(data.user.electronicSignature));
      }
      else
      {
        mutation.user.setSignature(data.user.electronicSignature);
        mutation.mergeTags.setPartnerSignature(data.user.electronicSignature); 
      }
      mutation.mergeTags.setPartnerFullName(
        `${data.user.firstName} ${data.user.lastName}`
      );
      mutation.mergeTags.setPartnerFirstName(data.user.firstName);
      mutation.mergeTags.setPartnerLastName(data.user.lastName);
      mutation.mergeTags.setPartnerEmail(
        data.user.registeredEmail?.toLowerCase().trim()
      );
      if (data.user.current_payment_provider) {
        mutation.payment.setPaymentAgreementComplete(true)
      } else {
        mutation.payment.setPaymentAgreementComplete(false)
      }

      if (data.user.payeeID) {
        mutation.payment.setPayeeIDAvailable(true)
      } else {
        mutation.payment.setPayeeIDAvailable(false)
      }
      // getDataForAppState()
    };

    const verifyStatus = (user) => {
      if (
        user.candidateStatus == "ON_HOLD" &&
        user.candidateStep == "COMPLETED"
      ) {
        gTagEvent("Partner Waitlisted", "Login", user?.partnerID)
      } else if (user.candidateStep == "APPROVED") {
        gTagEvent("Partner Approved", "Login", user?.partnerID)
      } else if (
        user.candidateStep == "SCREENING" &&
        ["MVR_DENIED", "CBC_DENIED", "DENIED"].includes(user.candidateStatus)
      ) {
        gTagEvent("Partner Rejected", "Login", user?.partnerID)
      }
    };

    const findLocationStatus = async (locationID, step, status) => {
      const allowedStatus = [
        "APPLICATION",
        "PERSONAL_DETAILS",
        "DOCUMENTS",
        "PAYMENT_AGREEMENT",
        "INSURANCE_VERIFICATION",
        "SCREENING",
        "COMPLETED",
        "APPROVED",
      ];

      const { data } = await signup.post("/validateLocationStatus", encodeWithParam({ clientLocationID: locationID }));
      if (data.success) {
        if (!step && !status) {
          return router.push({ name: "dashboard", query: { locationId: state.user.locationId } });
        }



        const { isRecruiting, isOnboarding, deleted } = data.result[0];
        if (!isRecruiting && (!isOnboarding || deleted)) {
          if (status === "Enrollment On-Hold") {
            return redirectToNoVacancy();
          } 
          else {
            return router.push({ name: "candidateDashboard" })
          }

        } 
        // else if (isRecruiting && step === "Account Creation" && status === "Enrollment On-Hold") {
        //   proxyApi.post('/addApplicationStatus', encodeWithParam({ status: FunnelStatus.ENROLLMENT_COMPLETE}));
        //   proxyApi.post('/addApplicationStatus', encodeWithParam({ status: FunnelStatus.PERSONAL_DETAILS_PENDING}));
        //   return router.push({ name: "personalDetails", query: { locationId: state.user.locationId } });
        // }

        return router.push({ name: "candidateDashboard" })
      }
    };
    
    const redirectToNoVacancy = () => {
      return router.push({
        name: "locationUpdate",
        query: { locationId: state.user.locationId },
      });
    };

    const numToString = (num: number) => {
      switch (num) {
        case 0: return "zero";
        case 1: return "one";
        case 2: return "two";
        case 3: return "three";
        default: return "";
      }
    }
    const  close=() => {
				showsendotp.value=false;
          return router.push({ name: "login" });
				}

    const  login=async () => {
      invalidAcc.value = false;
        const paramObj = { username: username.value.trim() }
        let obj = {
        username: username.value.trim(),
        password: password.value
      }
        signup.post("/validate-user", encodeWithParam(obj)).then((res)=> {
          const { data } =res
          if(data?.accountLocked){
            sessionStorage.setItem('notified', 'true')
            return router.push({
                name: "accountLockedCA",
              });
               
          }
          if(data.success){

            if(data?.isTwoFAdisabled == false){
              isSignupFlag.value = true;
              signIn()
            }else{
              lastFourPhone.value = data?.result ? data.result:'';
            signup.post("/generateOTPCA", encodeWithParam(paramObj)).then((result) => {
              
                if(!result.data.success) {
                    isFreezingTime.value = true;
                }
                else{
                    showsendotp.value=true;
                    invalid.value=false;
                  }
            })
            }

            
          }
          else{
            invalidAcc.value=true;
          }
        })



			}

        const getOTP=async()=>{
          const paramObj = { username: username.value.trim() }
          await signup.post("/generateOTPCA", encodeWithParam(paramObj))
        .then((result) => {
          
          if((!result.data.success)) {
            isFreezingTime.value = true;
          }
        })
      }

    //    const checkAllFieldsFilled=()=> { 
    //   isVerifyButtonDisabled.value = otpValues.value.some(value => value === '');
    // }
    const otpValue = ref('');
    const isOtpComplete = computed(() => {
      return otpValue.value.length === 6; 
    });
    const handleOtpChange = (otp: string) => {
      otpValue.value = otp;
    };
    const validations=()=>{
      isFreezingTime.value=false;
      invalid.value=false;
    };

    const openHelpTextDoc=()=>{
      micromodal.show("otp-expiration-help-text");
    };
    const closeModal = ()=>{
            micromodal.close("otp-expiration-help-text");
        }
        
    return {
      signIn,
      togglePassword,
      initialValues,
      invalid,
      header,
      error,
      errorMessage,
      expiredPassword,
      schema,
      viewPassword,
      passwordHidden,
      redirectToForgotPassword,
      countLeft,
      username,
      password,
      isLoginButtonDisabled,
      //otpValues, handleInput, handleBackspace,
      showsendotp,close,
      // verify,
      otp,login,
      // initialValues1,
      // schema1,
      //isVerifyButtonDisabled,
      // checkAllFieldsFilled
      otpValue,
      isOtpComplete,
      handleOtpChange,
      getOTP,
      isFreezingTime,
      invalidAcc,
      invalidOtp,
      isSignupFlag,
      validations,
      openHelpTextDoc,
      closeModal,
      lastFourPhone
    };
  },
});
