
import { state } from '@/harlem/stores'
import { useRouter } from 'vue-router'
import { proxyApi } from "@/http/http.config";
import { setStatus } from '@/services/stage.service'
import checklistMethods from "../components/Checklist/Helpers/Index";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import { onMounted, onUnmounted } from 'vue';
import { gTagEvent } from '@/util/GoogleAnalytics';
import InitChecklist from "../components/Checklist/InitChecklist.vue";

export default {
	name: 'accountProcessing',
	components: {
		FadeLoader,
		InitChecklist
	},
	methods: {
		isMobile() {
			return checklistMethods.isMobile();
		},
	},
	setup() {
		const router = useRouter()

		 

		const processStatus = async (status) => {
			if (status == 'Approved Manual' || status == 'Approved Auto') {
				router.push({ 
				name: 'ca-earnings-account-home', 
			})
			} 
		}
		
		let timeout: ReturnType<typeof setTimeout>
		let baseTimeout: ReturnType<typeof setTimeout>

		const checkStatus = () => {
			proxyApi.get('/getDriverStepstatus')
				.then(async ({data}) => {
					if (data.success > 0) {
						clearTimeout(timeout);
						await processStatus(data.result.status_name);
					}else{
					// 	router.push({ 
					// 	name: 'kycPending',
					// })
					redirectToStepper
					}
				})
				.finally(() => timeout = setTimeout(checkStatus, 2000))
		}

		const redirectToStepper = () => {
		  sessionStorage.setItem("cardType","PERSONAL_DETAILS")
          sessionStorage.setItem("progress","PERSONAL_DETAILS")
          sessionStorage.setItem("OCR_STATUS", "processing")
			router.push({ 
						name: 'enrollment-checklist',
					})
		}

		onMounted(() => {
			// timeout = setTimeout(checkStatus, 2000)
			// baseTimeout = setTimeout(() => {
			// 	clearTimeout(timeout);
			// 	router.push({ 
			// 			name: 'kycPending',
			// 		})
			// }, 30000)


			timeout = setTimeout(redirectToStepper, 2000)
			baseTimeout = setTimeout(() => {
				clearTimeout(timeout);
				// router.push({ 
				// 		name: 'kycPending',
				// 	})
				redirectToStepper
			}, 30000)
		})

		onUnmounted(() => {
			clearTimeout(timeout)
			clearTimeout(baseTimeout)
		})
	},
}
