import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { proxyApi } from "@/http/http.config";

const driverAccess:any = ["PersonalDirectDeposit", "PersonalDocuments-ca", "UploadCardDetails", "PersonalDocumentsEdit", "CaProfile", "ca-earnings-account-home", "ca-earnings-account", "ca-deposite-summary-home","enrollment-checklist"]
const ocrFailed:any = ["UploadCardDetails", "enrollment-checklist", "PersonalDirectDeposit", "CaProfile"];
const paymentAgreementCompleted:any = ["CaProfile", "enrollment-checklist", "PersonalDirectDeposit"];
const paymentAgreementPending:any = ["enrollment-checklist", "PersonalDirectDeposit"];
const waitListAccess:any = ["CaProfile", "enrollment-checklist", "PersonalDirectDeposit", "UploadCardDetails"];
const cpDenied:any = ["CaProfile", "enrollment-checklist", "PersonalDirectDeposit"];
const checkrSent:any = ["CaProfile", "enrollment-checklist", "PersonalDirectDeposit"];
const checkrSubmitted:any = ["CaProfile", "enrollment-checklist", "PersonalDirectDeposit"];
const dmsIssue:any = ["CaProfile", "enrollment-checklist", "PersonalDirectDeposit"];
const idvFail:any = ["fullProfileDetails", "kycFail"];

export default async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    try {
        const isAuthenticated = localStorage.getItem("access_token");
        if(!isAuthenticated) return next("/ca-login");
        
        let status:any = await proxyApi.get("/getUpdatedStatus");
        if(!status.data.success) throw new Error();
        status = status.data?.result[0];

        switch(status.candidateStatus){
            case "Payment Agreement Complete":
                return paymentAgreementCompleted.includes(to.name)? next() : next("/ca-signup/enrollment-checklist");
            case "Payment Agreement Pending":
                return paymentAgreementPending.includes(to.name)? next() : next("/ca-signup/personaldd");
            case "Personal Details Pending":
                return to.name == "fullProfileDetails"? next() : next("/ca-signup/fullProfileDetails");    
            case "Vehicle Info and Insurance Pending":
                return to.name == "UploadCardDetails"? next() : next("/ca-signup/uploadVehicleDetails");    
            case "Approved Auto": 
                return driverAccess.includes(to.name)? next() : next("/ca-dashboard/earnings-account-home");    
            case "Approved Manual":
                return driverAccess.includes(to.name)? next() : next("/ca-dashboard/earnings-account-home");    
            case "Application Waitlist":
                return waitListAccess.includes(to.name)? next() : next("/ca-signup/enrollment-checklist");    
            case "Withdrawn":
                return to.name == "enrollment-checklist"? next() : next("/ca-signup/enrollment-checklist");    
            case "Checkr Package Denied":
                return cpDenied.includes(to.name)? next() : next("/ca-signup/enrollment-checklist");    
            case "OCR Failed":
                return ocrFailed.includes(to.name)? next() : next("/ca-signup/enrollment-checklist");    
            case "Insurance Image Invalid":
                return ocrFailed.includes(to.name)? next() : next("/ca-signup/enrollment-checklist"); 
            case "Checkr Invitation Sent":
                return checkrSent.includes(to.name)? next() : next("/ca-signup/enrollment-checklist");
            case "Checkr Package Submitted":
                return checkrSubmitted.includes(to.name)? next() : next("/ca-signup/enrollment-checklist");
            case "DMS Data Issue":
                return dmsIssue.includes(to.name)? next() : next("/ca-signup/enrollment-checklist");
            case "IDV Fail":
                return idvFail.includes(to.name)? next() : next("/ca-signup/fullProfileDetails"); 
            default:
                return next();
        }
        
    } catch (error) {
        return next("/ca-login");
    }
  
};
