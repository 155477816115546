
import VuePdfEmbed from 'vue-pdf-embed';
import { defineComponent, ref, Ref, onMounted, computed, reactive} from "vue";
import InitChecklist from "../components/Checklist/InitChecklist.vue";
import checklistMethods from "../components/Checklist/Helpers/Index";
import DateDropDown from "@/components/forms/DateDropDown.vue";
import YearSelect from "@/components/forms/YearSelect.vue";
import { proxyApi } from "@/http/http.config";
import { IconWarningOctagon,IconAbout } from "@/components";
import { state } from "@/harlem/stores";
import router from "@/router";
import { useRoute } from "vue-router";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { FunnelStatus } from "@/util/FunnelStatus";
import { gTagEvent } from "@/util/GoogleAnalytics";
import * as yup from "yup";
import { Form, Field, configure } from "vee-validate";
import {convertBufferToContent} from "@/util/BufferContentConverter";

import {
  IneligibleModal,
  FieldError,
  // WalmartTaxConsentPolicy,
  StateSelectorValidated,
  TextFieldValidated,
} from "@/components";
import { makeBlock } from '@vue/compiler-core';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default defineComponent({
  name: "InsuranceUpload",
  components: {
    VuePdfEmbed,
    InitChecklist,
    DateDropDown,
    YearSelect,
    IconWarningOctagon,
    Form,
    TextFieldValidated,
    FieldError,
    Field,
    IconAbout,
    PulseLoader
  },
  methods: {
    isMobile() {
      return checklistMethods.isMobile();
    },
    
  },
  setup() {
    const loading = ref(false);
    const showSampleImage = ref(false);
    const isImage = ref(false);
    const route = useRoute();
    const fileURL: Ref<string | ArrayBuffer | null> = ref("");
    const previewImage = ref(false);
    const errorMessage = ref("");
    const errorMessaged = ref("");
    const formSubError = ref(false);
    const expiryDate = ref("");
    const insureTempId = ref("");
    const previewFile: Ref<File | null> = ref(null);
    const applicationId = route.query.applicationId;
    const locationID = route.query.locationID;

    const vehicleDetails = reactive({}) as {
      [key: string]: string | boolean | Date | null | undefined;
    };

    const errorMessageComp = computed(() => {
      return errorMessage.value;
    });

    const errorMessageDate = computed(() => {
      return errorMessaged.value;
    });
    const showBackbutton=()=>{
      if(sessionStorage.getItem("referer") == 'dashboard'){
        return true;
      }
      return false;
    }

 

    const saveDetails = async (data: any) => {

      
      if(fileURL.value) {
        if(expiryDate.value) {

          const formData = new FormData();
            let type = "ocr";
            let isUploadInsurance="true";
            formData.append("file", previewFile.value || "");
            formData.append("documentTemplateID", insureTempId.value);
            formData.append("signature", state.user.signature);
            formData.append("type", type);
            formData.append("originalname",previewFile.value?.name || "insurance image");
            formData.append("acl", "public");
            formData.append("insuranceExpiry",expiryDate.value);
            formData.append("addInsuranceInfo",isUploadInsurance);

            formData.append("color",data.colour);
            formData.append("licence_plate",data.licence_plate);
            formData.append("make",data.make);
            formData.append("model",data.model);
            formData.append("vehical_date",data.vehical_date);




          // let type = "ocr";
          //   data.file = previewFile.value || "";
          //   data.type = type;
          //   data.file.originalname = previewFile.value?.name || "insurance image";
          //   data.acl = 'public';
          //   data.insuranceExpiry = expiryDate.value;

            console.log(formData)
            updateVehicleDetails(formData);
        }else {
          errorMessaged.value="Insurance Date is not valid"
        }
      }else{ 
        errorMessage.value = "Please select file to upload";
      }
    }

    const goBack = ()=>{
      window.history.back()
    }
    const updateVehicleDetails = (obj) => {
      loading.value = true
      // console.log("submiited the form",obj);
      sessionStorage.setItem("callingPage","InsUpload")
      formSubError.value = false;
      proxyApi.post("/uploadFile", obj)
        .then(async ({ data }) => {
          loading.value = false;
          if(!data.success) {
            formSubError.value = true;
            return;
          }
          
          if(sessionStorage.getItem("referer") == 'dashboard'){
               
               return router.push({
                   name: "PersonalDocuments-ca",
                 });
             }

           // if comming from enrollment checklist
           if(sessionStorage.getItem("referer") == 'enrollment-checklist'){

                  if(sessionStorage.getItem("OCR_STATUS") ==  "insurance_expired" || sessionStorage.getItem("ocrFlag") == "false"){
                      sessionStorage.setItem("cardType","EARNINGS_STATUS")
                      sessionStorage.setItem("progress","EARNINGS_STATUS")
                  }else if(sessionStorage.getItem("OCR_STATUS") ==  "failed"){
                      sessionStorage.setItem("cardType","INSURANCE_UPLOAD")
                      sessionStorage.setItem("progress","INSURANCE_UPLOAD")
                      
                  }else{
                    sessionStorage.setItem("cardType","INSURANCE_UPLOAD")
                      sessionStorage.setItem("progress","INSURANCE_UPLOAD")
                  }
                  
                  sessionStorage.setItem("OCR_STATUS", "in-process")
                  return router.push({
                    name: "enrollment-checklist",
                  });
              }
          
          if(data.result == 'enrollment-checklist'){
              sessionStorage.setItem("cardType","INSURANCE_UPLOAD")
              sessionStorage.setItem("progress","INSURANCE_UPLOAD")
              sessionStorage.setItem("OCR_STATUS", "in-process")
              return router.push({
                name: "enrollment-checklist",
              });
          }
         
         

         


          sessionStorage.setItem("cardType","EARNINGS_ACCOUNT")
          sessionStorage.setItem("progress","EARNINGS_ACCOUNT")
          if (checklistMethods.isMobile()) {
            return router.push({
                name: "checklist",
              });
            }else{
              return router.push({
                name: "PersonalDirectDeposit",
              });
            }
        }) 
        .catch(error=>{
          console.log("exception occured while uploading insurance ", error.message);
          formSubError.value = true;
          loading.value = false;
        })
    }

    const showSample = () => {
      showSampleImage.value = true;
    }

    const resetFlag = () => {
      showSampleImage.value = false;
    }

    const uploadImage = (event: any) => {
      const input: HTMLInputElement = event.target;
      let acceptedFormat = ["jpg", "png", "jpeg", "gif", "pdf"];
      if (input.files) {
        // let fileFormat = input.files.item(0)?.name.split(".")[1].toLowerCase();
        let nameOfFile = input.files.item(0)?.name;
        let fileFormat = nameOfFile?.substring(nameOfFile.lastIndexOf(".")+1);
        // console.log("fileFormat",fileFormat)
        if(fileFormat == "pdf"){
          isImage.value = false;
        }else{
          isImage.value = true;
        }

        let format = acceptedFormat.some((o) => fileFormat?.includes(o));
        // console.log(format)
        let fileSize: number = input.files.item(0)?.size || 0;
        if (fileSize < 5242880 && fileSize && format) {
          // console.log(event.target.files)
          previewFile.value = event.target.files[0]
          fileURL.value = URL.createObjectURL(event.target.files[0]);
          previewImage.value = true;
          errorMessage.value = "";
        } else {
          errorMessage.value = "Not a valid file"
        }
      } else {
        errorMessage.value = "Not a valid file"
      }
    }

    const expirydateValidate = (e: any) => {
      expiryDate.value = e;
      if (isFutureDate(e)) {
        errorMessaged.value = ""
      } else {
        console.log("not future date")
        // const datePattern = /\d\d\d\d-\d\d-\d\d/g;
        // console.log(datePattern.test(e), e, "test result")
        // if (datePattern.test(e))
        errorMessaged.value = "Insurance should be current and active for the next 45 days."
      }
    }

    const dateValidate = (data:any) => {
      if(data == ""){
        errorMessage.value = "Please select date"
      }
    }

    const isFutureDate = (dateVal) => {
      const g1 = new Date();
      g1.setDate(g1.getDate() + 45);
      const g2 = new Date(dateVal); 
      console.log(g1.getTime() < g2.getTime(), "result is ")
      if (g1.getTime() < g2.getTime()) return true;
      else if (g1.getTime() > g2.getTime()) return false;
    } 

    onMounted(() => {

      
      proxyApi.get("/getDriverVehicalDetails").then(({ data }) => {
        if (data.success) {
          vehicleDetails.make = data.result.vehicle_make? data.result.vehicle_make: "";
          vehicleDetails.model = data.result.vehicle_model?data.result.vehicle_model : ""
          vehicleDetails.colour = data.result.vehicle_colour?data.result.vehicle_colour:""
          vehicleDetails.vehical_date = data.result.vehicle_year?data.result.vehicle_year:""
          vehicleDetails.licence_plate = data.result.vehicle_licence_plate?data.result.vehicle_licence_plate:""
          // vehicleDetails.expiry_date = data.result.vehicle_insurance_expiry_date
        }
      });

      proxyApi.get("/getOnBoardingDocuments").then(({ data }) => {
        if (data.success) {
          const docDeatils = data.result.filter(item => {
          return ["Insurance"].includes(item.title)
        })

        // insuranceFileId.value = docDeatils[0].fileID
        insureTempId.value = docDeatils[0].id

          if(docDeatils[0]) {
          loading.value = true;
          proxyApi.post('/getUploadedFile', encodeWithParam({ fileID : docDeatils[0].fileID}))
            .then(({data}) => {
              if(data.success){
                let fileFormat = data.fileName?.substring(data.fileName.lastIndexOf(".")+1);
            // console.log("fileFormat",fileFormat)
                if(fileFormat == "pdf"){
                  isImage.value = false;
                }else{
                  isImage.value = true;
                }
                  
                  fileURL.value =  data.bufferInHex ? convertBufferToContent(data.bufferInHex,data.contentType) : null
                  previewImage.value = true;
              }else{
                previewImage.value = false;
              }
              loading.value = false;
          })
        }
        }
      });
      
    })

    const schema = yup.object().shape({
      make: yup
        .string()
        .required("Make is required"),
      model: yup
        .string()
        .required("Model is required"),
      vehical_date: yup
      .string()
        .required("Year is required"),
      colour: yup
        .string()
        .required("Colour is required"),
      licence_plate: yup
        .string()
        .required("Licence Plate is required")
    })

    const sendCodeDisable=(errors)=>{
      let sendModel= document.querySelector('input[name="model"]') as HTMLInputElement;
      let sendMake = document.querySelector('input[name="make"]') as HTMLInputElement
      let sendLicPlate = document.querySelector('input[name="licence_plate"]') as HTMLInputElement

      if(Object.keys(errors).length > 0  || !fileURL.value || sendModel?.value =='' || sendMake?.value=='' || sendLicPlate?.value == '' || errorMessaged.value || expiryDate?.value == ""){
        return true;
            }else{
                return false;
            }
    }
    
    return { 
      resetFlag,
      showSampleImage,
      isImage,
      saveDetails,
      updateVehicleDetails,
      uploadImage,
      showSample,
      previewImage,
      fileURL,
      errorMessageComp,
      errorMessageDate, 
      expiryDate,
      expirydateValidate,
      dateValidate,
      // submit,
      schema,
      vehicleDetails,
      sendCodeDisable,
      loading,
      formSubError,
      showBackbutton,
      goBack,
    };
  },
});
