
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  computed,
} from "vue";
import { Form, Field, configure } from "vee-validate";

import {
  IneligibleModal,
  FieldError,
  // WalmartTaxConsentPolicy,
  StateSelectorValidated,
  TextFieldValidated,
} from "@/components";
import * as yup from "yup";
import micromodal from "micromodal";
import { DriversLicenseValidation } from "@/util/DriverLicenseValidation";
import { DateTime } from "luxon";
import { state } from "@/harlem/stores";
import { mutation } from "@/harlem/mutation";
import { useRoute, useRouter } from "vue-router";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import DateDropDown from "@/components/forms/DateDropDown.vue";
import Autocomplete from "vue3-autocomplete";
import { isCountyRequired } from "@/util/AddressUtilities";
import Base64 from "base-64";
import { proxyApi, restApi, kycApi } from "@/http/http.config";
import { decrypt, encodeWithParam, encrypt } from "@/util/DataEncodeDecode";
import InitChecklist from "../components/Checklist/InitChecklist.vue";
import checklistMethods from "../components/Checklist/Helpers/Index";
import { setStatus } from "@/services/stage.service";
import SsnModal from '@/modules/signup/personalDetails/SsnModal.vue';
import employerIdentificationModal from '@/modules/signup/personalDetails/employerIdentificationModal.vue';
// import employerRegisterBusinessModal from '@/modules/signup/personalDetails/employerRegisterBusinessModal.vue';
// import SelectorValidated from "@/components/forms/SelectorValidated.vue";
// import { IconWarningOctagon } from "@/components";
import {
  nullAddress,
  taxClassifications,
} from '@/modules/signup/personalDetails/constants'
import {
  AddressData,
  Suggestion
} from '@/modules/signup/personalDetails/interfaces'
import { selectHttpOptionsAndBody } from "@apollo/client/core";
import { validations } from "@/util/Validations";
import { gTagEvent } from "@/util/GoogleAnalytics";
import CommonService from "@/services/common.service";
import { setBusinessFlag } from "@/harlem/mutation/business.mutation";

export default defineComponent({ 
  name: "FullProfileDetails",
  components: {
    // IconWarningOctagon,
    FieldError,
    IneligibleModal,
    StateSelectorValidated,
    TextFieldValidated,
    // WalmartTaxConsentPolicy,
    Field,
    Form,
    PulseLoader,
    DateDropDown,
    Autocomplete,
    InitChecklist,
    SsnModal,
    employerIdentificationModal,
    // employerRegisterBusinessModal,
    // SelectorValidated
  },
  data() {
    return {
      isDobError: false,
      isExpiryError: false,
      cssnError: false,
    };
  },
  setup() {
    configure({
      validateOnModelUpdate: true,
    });
    let duplicateSIN = ref(false);
    let duplicateDL = ref(false)
    let ssnHidden = ref(true);
    let einHidden = ref(true);
    let taxHidden = ref(true);
    let cssnHidden = ref(true);
    let licenseHidden = ref(true)


    const toggleEyeSsn = () => { ssnHidden.value = !ssnHidden.value }
    const toggleEyeEin = () => { einHidden.value = !einHidden.value }
    const toggleEyeTax = () => { taxHidden.value = !taxHidden.value}
    const ctoggleEyeSsn = () => { cssnHidden.value = !cssnHidden.value }
    const toggleEyeLicense = () => { licenseHidden.value = !licenseHidden.value }

    const viewSsn = computed(() => ssnHidden.value ? "password" : "text");
    const viewEin = computed(() => einHidden.value ? "password" : "text");
    const viewtax = computed(() => taxHidden.value ? "password" : "text");
    const cviewSsn = computed(() => cssnHidden.value ? "password" : "text");
    const ssnInputMask = computed(() => ssnHidden.value ? "#########" : "###-###-###")
    const cssnInputMask = computed(() => cssnHidden.value ? "#########" : "###-###-###")
    const einMask = computed(() => einHidden.value ? "#########" : "#########")

    const viewLicense = computed(() => licenseHidden.value ? "password" : "text");
    const selectedBusinessState = ref('')
    const selectedState = ref('');
    const oldestAllowed = DateTime.utc()
      .minus({ years: 18, days: 1 })
      .toJSDate();
    const currentDate = DateTime.utc().toJSDate();
    const valid = ref(false);
    const loading = ref(false);
    const addressDisable = ref(true);
    const autoComplete = ref<InstanceType<typeof Autocomplete>>();
    const statePersonalSelected = ref<InstanceType<typeof StateSelectorValidated>>();
    const stateBusinessSelected = ref<InstanceType<typeof StateSelectorValidated>>();
    const showQuestions = ref(true);
    const suggestionText = ref(true);
    const warningText = ref(false);
    const responseSSN = ref({ ssn: "", boolean: true });
    const responseLicense = ref({ license: "", boolean: true });
    const router = useRouter();
    const isBusiness = ref(state.business.isBusinessSelected);
    const suggestions = ref<Suggestion[]>([])
    const businessaddressDetails = ref<AddressData>(nullAddress)
    const otherTaxClass = ref(false)
    const einValidmess = ref('')
    const isStateSelected = ref(false)
    const businessaddressDisable = ref(true)
    const eTaxErr = ref(false)
    const eTaxOpen = ref(false);
    const newOne = ref(false);
    const eTaxError = ref('')
    const disableAdd2 = ref(false);
    const postLength = ref(false);
    const postLengthbusiness=ref(false);
    const restAdd2 = ref(["pobo", "pobox"]);
    const restrictedAddress = ref(["pob", "pobo", "pobox", "bo", "box", "PO", "PO", "BOX", "PO B", "PO BO", "P.O. BOX", "P.O BOX", "PO. BOX", "POBOX", "PO BOX", "PO    BOX", "P.O BO", "PO. B", "P O Box", " PO  BOX", "PO BOX", "PO  B", "PO  BO", "P O BOX", "P O B", "P OB", "P O B", "P.O. B", "PO B", "P O", "P O", "P.O", "P.O ", "P.O  ", " P.O", "  P.O", "P.O B", "P.O  B", "P.O.  BO", "P.O.  BOX", "P.O.  B", " P.O.", "  P.O.", "  P.O. B", "  P.O. BO", "  P.O.  BOX", "p.o", "po", "p.o.", " po", "  po", "  po box", "p o", " p.o.", "p o box", "P.o", "p.O", "Po", "p.O"])
    const tworestrictedAddress = ref(["p", "pob", "pobo", "pobox", "bo", "box", "PO", "PO", "BOX", "PO B", "PO BO", "P.O. BOX", "P.O BOX", "PO. BOX", "POBOX", "PO BOX", "PO    BOX", "P.O BO", "PO. B", "P O Box", " PO  BOX", "PO BOX", "PO  B", "PO  BO", "P O BOX", "P O B", "P OB", "P O B", "P.O. B", "PO B", "P O", "P O", "P.O", "P.O ", "P.O  ", " P.O", "  P.O", "P.O B", "P.O  B", "P.O.  BO", "P.O.  BOX", "P.O.  B", " P.O.", "  P.O.", "  P.O. B", "  P.O. BO", "  P.O.  BOX", "p.o", "po", "p.o.", " po", "  po", "  po box", "p o", " p.o.", "p o box", "P.o", "p.O", "Po", "p.O"])



    if (sessionStorage.getItem("white_label") === "customCss") {
      showQuestions.value = false;
    }
    // const { updatePartnerApplication, saveCandidateProgress } = signupComposable();
    const route = useRoute();
    const locationId = route.query.locationId;
    const populate = route.query.populate
    const locationName = route.query.name;
    let addressSuggestions: any = ref([]);
    let businessaddressSuggestions: any = ref([]);
    const personalDetails = reactive({}) as {
      [key: string]: string | boolean | Date | null | undefined;
    };
    const checkChange =()=>{
      // personalDetails.consent = e.target.value
      console.log(personalDetails.consent)
      

    }

    let addressSelectedFromGoogle = ref(false);
    const disableFunction = (errors)=>{
      let cssnVal= document.querySelector('input[name="cssn"]') as HTMLInputElement;
      let driver_license= document.querySelector('input[name="driver_license"]') as HTMLInputElement
      let checkbovVal = document.querySelector('input[name="consent"]') as HTMLInputElement;

      // console.log(checkbovVal,"cssnVal.value")
      // personalDetails.consent = checkbovVal.value;
      if(cssnVal.value == '' || driver_license.value == '' || personalDetails.consent == false || postLength.value || postLengthbusiness.value ){
        return true
      }else{
        return false
      }


    }

    const autocompleteGoogle = (searchText: string,inpt) => {
      addressSelectedFromGoogle.value = false;
      let input = searchText; //place_idc
      // let input = searchText2
      // let country = 'country:CA';
      let type = 'address';
      // let dataToSend = [];
      // dataToSend['input'] = "ab";
      proxyApi.post('/placeAutocomplete', encodeWithParam({ inputval: input,  type: type }))
        .then((data) => {
          if(inpt == 'home'){
            addressSuggestions.value = data.data.result.predictions.map(item => {
            return {
              name: `${item.description}`,
              item: `${item.place_id}`,
            };
          })
          }else if(inpt == 'business'){
            businessaddressSuggestions.value = data.data.result.predictions.map(item => {
            return {
              name: `${item.description}`,
              item: `${item.place_id}`,
            };
          })
          }
          

          })
         
        
    };
    

    // personalDetails.dateOfBirth = "";
    personalDetails.consent = false;
    personalDetails.license = "";
    personalDetails.licenseState = "";
    // personalDetails.license_expiry_date = "";
    personalDetails.ssn = "";
    personalDetails.address1 = "";
    personalDetails.address2 = "";
    personalDetails.city = "";
    personalDetails.state = "";
    personalDetails.county = "";
    personalDetails.zip = "";
    personalDetails.businessName = "";
    personalDetails.ein = "";
    personalDetails.businessaddress1 = "";
    personalDetails.businessaddress2 = "";
    personalDetails.businesscity = "";
    personalDetails.businessstate = "";
    personalDetails.businesscounty = "";
    personalDetails.businesszip = "";
    personalDetails.taxClassification = "";
    personalDetails.taxClassificationOther = "";
    const initialTouched = reactive({}) as { [key: string]: boolean };
    const enableEdit=ref(true);
    const enableEditbusiness=ref(true)


    const selectedAddress = (selectedAddress: any, setFieldValue: any,validate:any,inpt) => {
      let placeID = selectedAddress.item;
      proxyApi.post('/getPlaceDetails', encodeWithParam({ place_id: placeID }))
        .then((data: any) => {
          let addressComponents = data.data.result.result.address_components;
          if (addressComponents.length > 0) {
            const province = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name || '';
            const address1 = (addressComponents.find(component => component.types.includes('street_number'))?.long_name || '') + ' ' + (addressComponents.find(component => component.types.includes('route'))?.long_name || '');
            const city = addressComponents.find(component => component.types.includes('locality'))?.long_name || '';
            const PostalCode = addressComponents.find(component => component.types.includes('postal_code'))?.short_name || '';

           
            // let formattedAddress = ` ${Province} , ${address1} , ${city} , ${PostalCode}`;
          
           
             if(inpt == 'home'){
            personalDetails.address1 = address1;
            personalDetails.city = city.normalize('NFD')
                   .replace(/[\u0300-\u036f]/g, '');
            // if(city == "Montréal"){
            //   personalDetails.city = city.replace("Montréal","Montreal")
            // }
            // if(city != ""){
            //   personalDetails.city = city;
            // }else if (city == 'Montréal'){
            //   console.log("atleast detected")
            //  let newVal= city.replace("Montréal","Montreal")
            //  personalDetails.city= newVal
            // }
            personalDetails.state = province.normalize('NFD')
                   .replace(/[\u0300-\u036f]/g, '');
            personalDetails.zip = PostalCode;
            // if(province == "Québec"){
            //   personalDetails.state = province.replace("Québec","Quebec")
            // }
            setFieldValue('state', province);
            setFieldValue('address1',address1);
            setFieldValue('city',city);
            setFieldValue('zip',PostalCode);
            addressSelectedFromGoogle.value= true;
            if(PostalCode=='' || PostalCode.length <5){
              enableEdit.value = false;
              postLength.value=true
             }else{
              enableEdit.value=true;
              postLength.value=false


             }

             }else if(inpt == 'business'){
              personalDetails.businessaddress1 = address1;
              personalDetails.businesscity = city.normalize('NFD')
                   .replace(/[\u0300-\u036f]/g, '');
              personalDetails.business_state =province.normalize('NFD')
                   .replace(/[\u0300-\u036f]/g, '') ;
              personalDetails.businesszip =PostalCode ;
            //   if(city == "Montréal"){
            //   personalDetails.businesscity = city.replace("Montréal","Montreal")
            // }
            // if(province == "Québec"){
            //   personalDetails.business_state = province.replace("Québec","Quebec")
            // }

              setFieldValue('business_state', province);
             setFieldValue('businessaddress1',address1);
             setFieldValue('businesscity',city);
             setFieldValue('businesszip',PostalCode);
             addressSelectedFromGoogle.value= true;
             if(PostalCode=='' || PostalCode.length <5){
              enableEditbusiness.value = false;
              postLengthbusiness.value=true
             }else{
              enableEditbusiness.value=true;
              postLengthbusiness.value=false


             }

             }

            //  console.log(personalDetails.businesscity,"shshshshshshshshshshshs")

          }
          else {
            console.log("failllll")
          }
        })}

    onMounted(async () => {
      const progress: any = localStorage.getItem("progress")
      const progressObj = JSON.parse(progress)

      await proxyApi.post('/addApplicationStatus', encodeWithParam({ stepName: 'PERSONAL_DETAILS', stepStatus: 'INCOMPLETE' }));
      setStatus("PERSONAL_DETAILS", "INCOMPLETE")

      if (state.signup.personalDetails) {
        for (const [key, value] of Object.entries(
          state.signup.personalDetails
        )) {
          personalDetails[key] = value as any;
          initialTouched[key] = true;
        }
      }
      
      proxyApi.get('/getDriversPersonalDetails').then(async (data) => {
        // console.log(data)
        personalDetails.firstName = data.data.result[0]?.firstName || '';
        personalDetails.lastName = data.data.result[0]?.lastName || '';
        personalDetails.dateOfBirth = data.data.result[0]?.dateOfBirth?.substr(0, 10) || '';
        personalDetails.driver_license = data.data.result[0]?.driver_license || '';
        personalDetails.licenseState = data.data.result[0]?.state || 'CA';
        personalDetails.license_expiry_date = data.data.result[0]?.expiration?.substr(0, 10) || '';
        personalDetails.driver_license = data.data.result[0]?.number || '';
        personalDetails.address1 = data.data.result[0]?.address1 || '';
        personalDetails.licence_state = data.data.result[0]?.dlState || '';
        // personalDetails.address2 = data.data.result[0]?.address2 || '';
        personalDetails.address3 = data.data.result[0]?.address3 || '';
        personalDetails.city = data.data.result[0]?.city || '';
        personalDetails.state = data.data.result[0]?.state || '';
        personalDetails.country = data.data.result[0]?.country || '';
        personalDetails.zip = data.data.result[0]?.postalCode || '';
        personalDetails.ssn = await decrypt(data.data.result[0]?.ssn) || '';
        addressSelectedFromGoogle.value= true;

        if(data.data.result[0]?.tax_eligible){
          isBusiness.value = true;
          personalDetails.businessName = data.data.result[0]?.businessName || '';
          personalDetails.ein = data.data.result[0]?.ein || '';
          personalDetails.businessaddress1 = data.data.result[0]?.Baddress1 || '';
          personalDetails.businesscity = data.data.result[0]?.Bcity || '';
          personalDetails.business_state = data.data.result[0]?.Bstate || '';
          personalDetails.businesszip = data.data.result[0]?.Bzip || '';
          personalDetails.taxNo = data.data.result[0]?.federalTaxClassification || '';
          localStorage.setItem("isBusiness", JSON.stringify(isBusiness.value))
        }

        if(JSON.parse(localStorage.getItem('driverDetails') || "false")){
         let personalDetailsOfDriver = JSON.parse(localStorage.getItem('driverDetails') || "false");
         personalDetails.dateOfBirth = personalDetailsOfDriver.dateOfBirth;
         personalDetails.driver_license = personalDetailsOfDriver.driver_license;
        personalDetails.licence_state = personalDetailsOfDriver.licence_state;
        personalDetails.license_expiry_date = personalDetailsOfDriver.license_expiry_date;
        personalDetails.address1 = personalDetailsOfDriver.address1 || '';
        personalDetails.address2 = personalDetailsOfDriver.address2 || '';
        personalDetails.address3 = personalDetailsOfDriver.address3 || '';
        personalDetails.city = personalDetailsOfDriver.city || '';
        personalDetails.state = personalDetailsOfDriver.state || '';
        personalDetails.country = personalDetailsOfDriver.country || '';
        personalDetails.zip = personalDetailsOfDriver.zip || '';
        personalDetails.ssn = personalDetailsOfDriver.ssn;
        personalDetails.cssn = personalDetailsOfDriver.ssn;
        addressSelectedFromGoogle.value= true;

        personalDetails.businessName = personalDetailsOfDriver.businessName;
        personalDetails.ein = personalDetailsOfDriver.ein;
        personalDetails.businessaddress1 = personalDetailsOfDriver.businessaddress1;
        personalDetails.businessaddress2 = personalDetailsOfDriver.businessaddress2;
        personalDetails.businesscity = personalDetailsOfDriver.businesscity;
        personalDetails.business_state = personalDetailsOfDriver.business_state;
        personalDetails.business_state = personalDetailsOfDriver.business_state;
        personalDetails.businesszip = personalDetailsOfDriver.businesszip;
        personalDetails.taxNo = personalDetailsOfDriver.taxNo;
        personalDetails.taxClassificationOther = "";
      }
    
    })

    // if (populate === 'true') {
    //       personalDetails.cssn = personalDetails.ssn
    //       newOne.value = true;

    //     }

      if (JSON.parse(localStorage.getItem("isBusiness") || "false")) {
        isBusiness.value = true
      }

     
      selectedState.value = '';
      setStatus("PERSONAL_DETAILS", "INCOMPLETE")
     

      gTagEvent("Show SSN Tooltip", "Personal Details", `partner_id: ${state.user.partnerId}`)
    });
   
    const openModal = (modal: string) => {
      newOne.value = true;
      micromodal.show(modal);

    };

   

   const postManual=(e)=>{
    // console.log(e.target.value,"post new")
    const regex1= /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
    // if(e.target.value.replace(/ /g,'').length !=6){
    //   postLength.value=true
    // }else if(regex1.test(e.target.value)){
    //   console.log("here it is")
    //   postLength.value=true
    // }else{
    //   postLength.value = false
    // }
    if( !regex1.test(e.target.value)){
      postLength.value=true
    }else{
      postLength.value = false
    }

   }
   const postManualBusiness =(e)=>{
    // if(e.target.value.replace(/ /g,'').length !=6){
    //   postLengthbusiness.value=true
    // }else{
    //   postLengthbusiness.value=false
    // }
    const regex= /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
    if( !regex.test(e.target.value)){
      postLengthbusiness.value=true
   }else{
      postLengthbusiness.value=false
    }}


    const toggleBusiness = () => {
      isBusiness.value = !isBusiness.value;
      setBusinessFlag(isBusiness.value);
      localStorage.setItem("isBusiness", JSON.stringify(isBusiness.value))
      gTagEvent("Toggle Business", "Business Details", `(Toggled: ${isBusiness.value}) (Partner id: ${state.user.partnerId})`)
      if (isBusiness.value) {
        proxyApi.post('/addApplicationStatus', encodeWithParam({
          stepName: 'BUSINESS_DETAILS',
          stepStatus: 'INCOMPLETE',
        }))
      }
      else {
        proxyApi.post('/addApplicationStatus', encodeWithParam({
          stepName: 'BUSINESS_DETAILS',
          stepStatus: 'INCOMPLETE',
        }))
      }
    }

    const validateSSN = async (value) => {
      value = value.split("-").join("");
      if (
        value.toLowerCase() &&
        responseSSN.value.ssn != value.toLowerCase() &&
        value.length == 9
      ) {
        const result = await proxyApi.post("/validateSSN", {
          param: Base64.encode(
            JSON.stringify({ ssn: value.toLowerCase() })
          )
        });
        responseSSN.value.ssn = value.toLowerCase();
        if (
          result.data.result.length > 0 &&
          result.data.result[0].partnerID != null
        ) {
          responseSSN.value.boolean = false;
          gTagEvent("Duplicate SSN", "Personal Details", `partner_id: ${state.user.partnerId}`)
          // micromodal.show('duplicate-user');
          return false;
        }
        responseSSN.value.boolean = true;
        return true;
      } else if (responseSSN.value.ssn == value.toLowerCase()) {
        return responseSSN.value.boolean;
      }
      return true;
    };
    const validateLicense = async (value) => {
      if (responseLicense.value.license != value) {
        const result = await proxyApi.post("/validateDL", {
          param: Base64.encode(
            JSON.stringify({ dl: value })
          )
        });
        responseLicense.value.license = value;
        if (
          result.data.result.length > 0 &&
          result.data.result[0].partnerID != null
        ) {
          gTagEvent("Duplicate Drivers License", "Personal Details", `partner_id: ${state.user.partnerId}`)
          responseLicense.value.boolean = false;
          return false;
        }
        responseLicense.value.boolean = true;
        return true;
      } else if (responseLicense.value.license == value) {
        return responseLicense.value.boolean;
      }
      return true;
    };

    function getMinDate() {
      const g1 = new Date();
      g1.setDate(g1.getDate() + 45);
      return g1;
}

    const schema = yup.object().shape({
      taxNo:yup.string().required('GST/HST is required'),
      consent: yup.bool().required('Please provide your consent.'),
      dateOfBirth: yup
        .date()
        .required("Date of Birth is required")
        .label("Date of Birth")
        .typeError("Date of birth is required")
        .test("dateOfBirth", "Name Validation Error", function (value:any) { 
          var today = new Date();
                var birthDate = new Date(value);
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }    
                // console.log("this is ",age)
            if (age < 18) {
              
              return this.createError({message: "Age should be more than 18" });
            } else{
              return true;
            }
    }), // D
        // .max(oldestAllowed, "Age should not be less than 18 years"),
      // businessaddress2: yup.string().trim().label("Address"),
      // .test("businessaddress2",
      //         "Please enter a valid address other than PO box",
      //         function (value: any) {

      //           return !restrictedAddress.value.some((v)=> value.indexOf(v) >= 0);
      //         }
      //       ),
      ssn: yup
        .string()
        .trim()
        .matches(
          /^\d{3}-?\d{3}-?\d{3}$/g,
          "SIN format invalid"
        )
        .typeError("SIN must be a number")
        // .notOneOf([yup.ref("ein")], "SSN and EIN should not be the same")
        .required("Social insurance number is required")
        // .test("SSN", "SSN is already in use", (value: any) => {
        //   // Please change the approch here it is hiiting n number of time 
        //   return validateSSN(value);
        // })
        // .min(9, "Social Insurance number is required")
        .label("SIN"),
      cssn: yup
        .string()
        .required("Confirm SIN is a required field")
      // .oneOf([yup.ref("ssn"), null], "SSN mismatched")
      ,
      confirmssn: yup
        .string()
        .trim()
        .required("Social Insurance numbers do not match") //confirm social Insurance number is required(doubt:should be changed in all .?)
        .min(9, "Social Insurance numbers do not match")
        .oneOf([yup.ref("ssn"), null], "Confirm social Insurance number is required"),
      driver_license: yup
        .string()
        .trim("License number can't contain white space at the start or end")
        .strict(true)
        .required("Driver's license number is required")
        .matches(/^(?!-)[A-Za-z0-9]+([A-Za-z0-9]+)*$/, "Invalid format for DL")
        ,
      // confirmlicense: yup
      //   .string()
      //   .trim("Matching license number can't contain white space at the start or end")
      //   .strict(true)
      //   .required("Driver's license number do not match")
      //   .oneOf([yup.ref("license"), null], "Driver License do not match"),
      licence_state: yup
        .string()
        .trim()
        .required(
          "State is a required field"
        ),
      license_expiry_date: yup
      .date()
        .required("License expiration date is required")
        .label("License Expiration")
        .min(getMinDate(), "The driver’s licence should be current and active for the next 45 days. ")
        .typeError("License is required")
        ,
       
      address1: yup.string().trim().required("Street address is required").label("Street Address"),
      //   .test("address1",
      //     "Please enter a valid home address other than PO box",
      //     function (value: any) {
      //       const bool = value.toLowerCase().split(' ').some((strItem: string) => restrictedAddress.value.includes(strItem));
      //       disableAdd2.value = bool;
      //       return !bool
      //     }
      //   ),
      // address2: yup.string().trim().label("Address")
      //   .test("address2",
      //     "Please enter a valid home address other than PO box",
      //     function (value: any) {
      //       const bool = value.toLowerCase().split(' ').some((strItem: string) => tworestrictedAddress.value.includes(strItem));
      //       disableAdd2.value = bool;
      //       return !bool
      //     }
      //   )
      //   .test("address2",
      //     "Please enter a valid home address other than PO box",
      //     function (value: any) {
      //       return !restAdd2.value.some((v) => value.indexOf(v) >= 0);
      //     }
      //   )
      //   .test("address2", "Address cannot include special characters",
      //     function (value: any) {
      //       const hasSpecialChar = /[^0-9a-zA-Z\s'/-]/
      //       if (hasSpecialChar.test(value)) {
      //         disableAdd2.value = true
      //         return false

      //       } return true;
      //     }
      //   ),
      // city: yup.string().trim("City can't contain white space at the start or end").strict(true).matches(/^[aA-zZ' ]+$/g, "Enter a valid city").required("City is required").label("City"),
      // state: yup.string().trim().required("Province is required").label("Province"),
      // county: yup
      //   .string()
      //   .trim("County can't contain white space at the start or end")
      //   .strict(true)
      //   .matches(/^[aA-zZ ]+$/g, "Enter a valid county")
      //   .when("state", {
      //     is: (val: string) => isCountyRequired(val),
      //     then: yup.string().min(4, "Enter a valid county ").required().label("County"),
      //     otherwise: yup.string().notRequired().label("County"),
      //   }),
      // zip: yup
      //   .string()
      //   .trim()
      //   .required("Postal code is required")
      //   .min(6, "Postal Code must be 6 digits")
      //   .matches(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i, "Postal code is invalid") 
      //   .label("{Zip} Code"),

      // firstName: yup
      //   .string()
      //   .required("First Name is required"),
      // lastName: yup
      //   .string()
      //   .required("Last Name is required"),  
      businessName: yup.string().required("Business name is required").label("Business Address")
        // .matches(/^[aA-zZ ]+$/g, "Business Name cannot include Spaces, Numbers and Special Characters")
        // .matches(/\s/,"Business Name should not contain spaces")
        // .test("businessName", function (value: any) {
        //   const space = /^\s|\s$/
        //   if (!space.test(value)) {
        //     return true
        //   } else {
        //     return this.createError({
        //       message: "Business Name cannot include Spaces, Numbers and Special Characters",
        //     });
        //   }
        // })
      ,
      ein: yup
        .string()
        .required()
        .test("ein", function (value: any) {
          const isvalidein = new RegExp(/^\d{9}$/);
          console.log(personalDetails.ein, "value of personalDetails.ein", value, " is the value of ein")
          if (
            value &&
            isvalidein.test(value.toString())
          ) {
            console.log("Test is passed")
            return true;
          } else if (value == "" || value == null) {
            console.log("Failed in checking regex")
            return this.createError({
              message: "EIN is required",
            });
          } else {
            console.log("insdie else")
            return this.createError({
              message: "EIN is invalid",
            });
          }
        })
        .label("EIN"),
      businessaddress1: yup.string().trim().required("Business address is required").label("Street Address"),
      // businesscity: yup.string().trim("City can't contain white space at the start or end").strict(true).matches(/^[aA-zZ' ]+$/g, "Enter a valid city").required("Business city is required").label("City"),
      // business_state: yup.string().trim().required("Business state is required").label("State"),
      // businesszip: yup
      // .string()
      //   .trim()
      //   .required("Postal code is required")
      //   .min(6, "Postal Code must be 6 digits")
      //   .matches(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/g, "Postal code is invalid")
      //   .label("Zip Code"),
      // businesscounty: yup
      //   .string()
      //   .trim("County can't contain white space at the start or end")
      //   .strict(true)
      //   .matches(/^[aA-zZ ]+$/g, "Enter a valid county")
      //   .when("state", {
      //     is: (val: string) => isCountyRequired(val),
      //     then: yup.string().min(4, "Enter a valid county ").required().label("County"),
      //     otherwise: yup.string().notRequired().label("County"),
      //   }),
      // taxClassification: yup.string().trim().required("Tax Classification is required").label("Tax Classification Other"),
      // taxClassificationOther: yup
      //   .string()
      //   .when("taxClassification", {
      //     is: () => otherTaxClass.value,
      //     then: yup.string().required("Other tax classification is required").test("taxClassificationOther", function (value: any) {
      //       if (validations.verification.hasSpecialChar.test(value) || validations.verification.hasNum.test(value) || validations.verification.hasWhitespace.test(value)) {
      //         return this.createError({
      //           message: "Other tax classification is invalid",
      //         });
      //       } else return true
      //     }),
      //     otherwise: yup.string().label("taxClassificationOther"),
      //   }),
    });

    let timeout = ref();
    // const findSuggestions =
    //   (
    //     setFieldValue: (key: string, value: string) => void,
    //     searchBusinessText: string
    //   ) => {
    //     businessaddressDisable.value = true
    //     if (searchBusinessText === '') {
    //       suggestions.value = []
    //       businessaddressDetails.value = nullAddress
    //       for (const key in nullAddress) {
    //         setFieldValue(key, nullAddress[key])
    //       }
    //       return
    //     }
    //     const regex = new RegExp(/^(PO.*BOX*)/);
    //     // if (!restrictedAddress.value.includes(searchBusinessText.trim().toUpperCase()) && searchBusinessText.trim().toUpperCase().search(regex) === -1) {
    //     //   let searchParams = encodeWithParam({ search: searchBusinessText })
          
    //     // }
    //   }

    

    const handleStateSelect = (state: string) => (
      isStateSelected.value = state === '' ? false : true
    )

    const einValid = (event) => {
      const regex = /[0]{2}-[0]{7}/
      if (regex.test(event.target.value)) {
        einValidmess.value = "not all zeroes"
      }
      else {
        einValidmess.value = ""
      }

      return einValidmess.value

    }

    const handleTaxSelect = (taxClassification: string) => {
      otherTaxClass.value = (taxClassification === 'other' ? true : false)
      personalDetails.taxClassification = taxClassification
    }

    const handleOtherTaxSelect = (taxClassificationOther: string) => {
      if (otherTaxClass.value) {
        personalDetails.taxClassificationOther = taxClassificationOther
      }
    }

    // const handleOpenSSNModal = () => {
    //   gTagEvent("Show SSN Tooltip", "Personal Details", `partner_id: ${state.user.partnerId}`)
    //   micromodal.show('modal-ssn')
    // }

    

    const updateDetails = (detail, e) => {
      personalDetails[detail] = e;
    }

    let addressSelectedFromSmarty = ref(false);
    // const getAddressSuggestion = (searchText: string) => {
    //   if (searchText.length === 0) {
    //     suggestionText.value = true;
    //     warningText.value = false;
    //   } else {
    //     suggestionText.value = false;
    //     warningText.value = true;
    //   }
    //   const regex = new RegExp(/^(PO.*BOX*)/);
    //   if (!restrictedAddress.value.includes(searchText.trim().toUpperCase()) && searchText.trim().toUpperCase().search(regex) === -1) {
    //     let searchParams = encodeWithParam({ search: searchText })
    //     proxyApi
    //       .post("/getAddressSuggestions", searchParams)
    //       .then(({ data }: any) => {
    //         if (data.result.length > 0) {
    //           addressSuggestions.value = data.result.map((item) => {
    //             return {
    //               name: `${item.streetLine}, ${item.city}, ${item.state}, ${item.zipcode}`,
    //               item,
    //             };
    //           });
    //         } else {
    //           addressSuggestions.value = [
    //             {
    //               name: "Please enter a valid address",
    //               item: {},
    //             },
    //           ];
    //           //addressDisable.value = false;
    //         }
    //       }).catch(() => {
    //         addressSuggestions.value = [
    //           {
    //             name: "Please enter a valid address",
    //             item: {},
    //           },
    //         ];
    //         //addressDisable.value = false;
    //       });
    //   } else {
    //     addressSuggestions.value = [];
    //     if (searchText.length === 0 && addressSelectedFromSmarty.value) {
    //       document.getElementById('address1')?.blur();
    //       personalDetails.address1 = "";
    //       personalDetails.address2 = "";
    //       personalDetails.city = "";
    //       personalDetails.state = "";
    //       personalDetails.zip = "";
    //       personalDetails.county = "";
    //       personalDetails.countyRegistered = "";
    //       selectedState.value = '';
    //       statePersonalSelected.value?.onChangeState(" ");
    //     }
    //   }
    // };
    const autoCompleteError = (errors: any) => errors.address1 ? ['has-error'] : []
    const addressSuggestionComputed = computed(() => addressSuggestions.value);
    const addressSuggestionComputedBus = computed(() => businessaddressSuggestions.value);

    // const selectedAddress = (selectedAddress: any, validate: any, setFieldValue: any) => {
    //   suggestionText.value = false;
    //   warningText.value = false;
    //   setFieldValue('address2', '');
    //   setFieldValue('city', '');
    //   setFieldValue('county', '');
    //   setFieldValue('state', '');
    //   setFieldValue('zip', '');
    //   if (Object.keys(selectedAddress.item).length > 0) {
    //     setFieldValue('address1', selectedAddress.item.streetLine);
    //     setFieldValue('address2', selectedAddress.item.secondary);
    //     setFieldValue('city', selectedAddress.item.city);
    //     setFieldValue('state', selectedAddress.item.state);
    //     setFieldValue('zip', selectedAddress.item.zipcode);
    //     personalDetails.address1 = selectedAddress.item.streetLine;
    //     personalDetails.address2 = selectedAddress.item.secondary;
    //     personalDetails.city = selectedAddress.item.city;
    //     personalDetails.state = selectedAddress.item.state;
    //     personalDetails.zip = selectedAddress.item.zipcode;
    //     autoComplete.value?.setText(selectedAddress.item.streetLine);
    //     // addressDisable.value = false;
    //     statePersonalSelected.value?.onChangeState(selectedAddress.item.state);
    //     selectedState.value = selectedAddress.item.state;
    //     addressDisable.value = true;
    //     addressSelectedFromSmarty.value = true;
    //   } else {
    //     //addressDisable.value = false;
    //     personalDetails.address1 = "";
    //     personalDetails.address2 = "";
    //     personalDetails.city = "";
    //     personalDetails.state = "";
    //     personalDetails.zip = "";
    //     personalDetails.county = "";
    //     personalDetails.countyRegistered = "";
    //   }
    // };


    const saveDetails = async (data: any) => {  
    //  console.log(data,"HERE IS DATA");
      localStorage.setItem("driverDetails",JSON.stringify(data));
      
                router.push({ 
                  name: 'personalInfoConfirmation', 
                })
           


        // const partnerSIN = data.ssn.split("-").join("");
        // let obj = {

        //   driverKYC: { 
        //     sin: partnerSIN,
        //     dlCountry: data.country,
        //     dlState: data.state,
        //     dlNumber: data.driver_license,
        //     dlExpiry: personalDetails.license_expiry_date,
        //   },
        //   partnerPersonalDetail: { 
        //     dob: data.dateOfBirth ? data.dateOfBirth : null,
        //     firstName: data.firstName,
        //     lastName: data.lastName
        //   },
        //   address: { 
        //     address1: data.address1,
        //     address2: data.address2,
        //     address3: data.address3, // personalDetails.address3,
        //     city: data.city,
        //     state: data.state,
        //     postalCode: data.zip,
        //     country: "CA", 
           
        //   }
        // };
        // proxyApi.post("/updateDriversDetails", encodeWithParam(obj))
        // .then(async ({ data }) => {
        //   // console.log(data)
        //   if(data.success){
        //     proxyApi.get('/driverKyc').then(async (data) => { personalInfoConfirmation
        //         router.push({ 
        //           name: 'kycProcessing', 
        //         })
        //     })
        //   }else if(!data.success && data.result == "SIN"){
        //     // console.log(data.message);
        //     // duplicateSIN.value = true;
        //     micromodal.show('modal-ineligible')
        //   }else if(!data.success && data.result == "DL"){
        //     // console.log(data.message);
        //     // duplicateDL.value = true;
        //     micromodal.show('modal-ineligible')
        //   }else{
        //     router.push({ 
        //           name: 'kycFail', 
        //         })
        //   }
          
        // }) 
    };

    // const updateStateValue = (category, e: any, setFieldError: any) => {
    //   if (category == "personal") {
    //     selectedState.value = e.target.value
    //     if (selectedState.value === 'CA') {
    //       setFieldError('county', 'County is required')
    //     } else {
    //       setFieldError('county', '')
    //     }
    //   } else if (category == "business") {
    //     selectedBusinessState.value = e.target.value
    //     if (selectedBusinessState.value === 'CA') {
    //       setFieldError('county', 'County is required')
    //     } else {
    //       setFieldError('county', '')
    //     }
    //   }
    // }

    return {
      duplicateSIN,
      duplicateDL,
      suggestions,
      taxClassifications,
      ssnHidden,
      toggleEyeSsn,
      viewSsn,
      licenseHidden,
      toggleEyeLicense,
      viewLicense,
      ssnInputMask,
      einMask,
      saveDetails,
      initialTouched,
      personalDetails,
      valid,
      state,
      schema,
      currentDate,
      oldestAllowed,
      loading,
      showQuestions,
      addressSuggestions,
      // getAddressSuggestion,
      selectedAddress,
      addressDisable,
      autoComplete,
      stateBusinessSelected,
      statePersonalSelected,
      // updateStateValue,
      selectedState,
      selectedBusinessState,
      autoCompleteError,
      addressSuggestionComputed,
      addressSuggestionComputedBus,
      suggestionText,
      warningText,
      handleTaxSelect,
      handleOtherTaxSelect,
      handleStateSelect,
      updateDetails,
      micromodal,
      isBusiness,
      // handleOpenSSNModal,
      toggleBusiness,
      // findSuggestions, 
      otherTaxClass,
      businessaddressDisable,
      openModal,
      eTaxError,
      eTaxErr,
      disableAdd2,
      viewEin,
      viewtax,
      einHidden,
      taxHidden,
      toggleEyeEin,
      toggleEyeTax,
      cssnHidden,
      ctoggleEyeSsn,
      cviewSsn,
      cssnInputMask,
      eTaxOpen,
      newOne,
      autocompleteGoogle,
      enableEdit,
      addressSelectedFromGoogle,
      disableFunction,
      checkChange,
      postLength,
      postManual,
      postLengthbusiness,
      enableEditbusiness,
      postManualBusiness
      // autocompleteGoogleBus,
      // selectedAddressBus
    };
  },
  methods: {
    dateValidate: function (data) {
      this.isDobError = false;
      let age = this.underAgeValidate(data);
      if (data.length === 10) {
        this.personalDetails.dateOfBirth = data
      }
      if (age <= 18) {
        this.isDobError = true;
      }
    },

    changeCSSN: function (e) {
      let ssnValue = document.querySelector('input[name="ssn"]') as HTMLInputElement;
      if (e.target.value.replace(/-/g, '') != ssnValue?.value.replace(/-/g, '')) {
        // gTagEvent("Update Address - Account Info", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
        this.cssnError = true;
        // }
      } else this.cssnError = false;
      if (e.target.value.length === 9) {
        if (e.target.value.replace(/-/g, '') != ssnValue?.value.replace(/-/g, '')) {
          gTagEvent("SSN Mismatch", "Personal Details", `partner_id: ${state.user.partnerId}`)
        }
      }
    },
    changeSSN: function (e) {
      this.duplicateSIN = false;
      let cssnValue = document.querySelector('input[name="cssn"]') as HTMLInputElement;
      if (e.target.value.replace(/-/g, '').length === 9 && cssnValue.value.length > 2) {
        if (e.target.value.replace(/-/g, '') != cssnValue?.value.replace(/-/g, '')) {
          return this.cssnError = true;
        } else this.cssnError = false;
      }
    },

    expirydateValidate: function (data) {
      this.isExpiryError = false;
      let futuredate = this.isFutureDate(data);
      if (data.length === 10) {
        this.personalDetails.license_expiry_date = data
      }
      if (!futuredate) {
        this.isExpiryError = true;
      }
    },
     
     underAgeValidate: function (birthday) { 
      const end = DateTime.now();
      const start = DateTime.fromISO(birthday);
      const diffInMonths = end.diff(start, "months");
      let age = diffInMonths.months / 12;
      return age;
    },
    isFutureDate: function (dateVal) {
      const g1 = new Date();
      const g2 = new Date(dateVal);
      if (g1.getTime() < g2.getTime()) return true;
      else if (g1.getTime() > g2.getTime()) return false;
    },
    displayAddressSuggestion: function (item) {
      return item.streetLine;
    },
    isMobile() {
      return checklistMethods.isMobile();
    },
    triggerHyphenCheck: function (e) {
      if ((e.key === "Backspace") && e.target.value.length > 0) {
        var str = e.target.value;
        if ("-" == str.charAt(str.length - 1)) {
          str = str.substring(0, str.length - 1);
        }
        e.target.value = str;
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
  },
});
